import React, { useContext, useEffect, useState } from "react";
import { EnvTypes, UserDataResponse, WalletManager } from "myria-core-sdk";
import { signMetamask } from "../helpers/myriaCoreSDK";

interface IWalletConnectContext {
  isConnected: boolean;
  walletAddress: `0x${string}`;
  starkKey: `0x${string}`;
  onConnectWallet: () => Promise<UserDataResponse>;
}
const WalletConnectContext = React.createContext<IWalletConnectContext>(
  {} as IWalletConnectContext
);
interface IProps {
  children: React.ReactNode;
}
const envCoreTypes: EnvTypes =
  process.env.REACT_APP_CORE_ENV === "STAGING"
    ? EnvTypes.STAGING
    : EnvTypes.PRODUCTION;

export const WalletConnectProvider: React.FC<IProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<`0x${string}`>("0x");
  const [starkKey, setStarkKey] = useState<`0x${string}`>("0x");

  useEffect(() => {
    const validateAccount = async () => {
      const localWalletAddress = localStorage.getItem("walletAddress");
      const localStarkKey = localStorage.getItem("starkKey");
      const walletconnectedMetamask = await signMetamask();
      if (
        localWalletAddress?.toLowerCase() ===
        walletconnectedMetamask[0].toLowerCase()
      ) {
        setIsConnected(true);
        setWalletAddress(localWalletAddress as `0x${string}`);
        setStarkKey(localStarkKey as `0x${string}`);
      } else {
        setIsConnected(false);
        localStorage.clear();
      }
    };
    validateAccount();
  }, []);

  const onConnectWallet = async () => {
    const walletManager = new WalletManager(envCoreTypes);
    try {
      const walletConnect = await walletManager.connectAndLogin();
      if(walletConnect) {
        setWalletAddress(walletConnect?.ethAddress as `0x${string}`);
        setStarkKey(walletConnect?.starkKey as `0x${string}`);
        localStorage.setItem(
          "walletAddress",
          walletConnect?.ethAddress as `0x${string}`
          );
          localStorage.setItem("starkKey", walletConnect?.starkKey as `0x${string}`);
        setIsConnected(true);
        return walletConnect;
      } else {
        setIsConnected(false);
        throw new Error('Wallet connect and login failed!')
      }
    } catch (error) {
      setIsConnected(false);
      throw error;
    }
  };

  return (
    <WalletConnectContext.Provider
      value={{
        isConnected,
        walletAddress,
        starkKey,
        onConnectWallet,
      }}
    >
      {children}
    </WalletConnectContext.Provider>
  );
};

export const useWalletConnect = () => useContext(WalletConnectContext);
