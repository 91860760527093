import { useCallback, useEffect, useState } from "react";
import "./App.css";
import styles from "./app.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnvTypes } from "myria-core-sdk";

// unity-react-webgl dependencies
import { Unity, useUnityContext } from "react-unity-webgl";
import React from "react";
// import { IWager } from "myria-game-pvp-sdk";
import { Modules, Types } from "myria-game-pvp-sdk";
import { useWalletConnect } from "./context/WalletConnectContext";

function App() {
  const { onConnectWallet, isConnected, walletAddress, starkKey } =
    useWalletConnect();
  const [clientId, setClientId] = useState("");
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    takeScreenshot,
    unload,
  } = useUnityContext({
    loaderUrl: "Build/public.loader.js",
    dataUrl: "Build/public.data",
    frameworkUrl: "Build/public.framework.js",
    codeUrl: "Build/public.wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const alertUnityWithURL = () => {
    const fullURL = window.location.href; // Gets the full URL
    // Assuming you have a GameObject in Unity called 'URLReceiver' 
    // and a method 'ReceiveURL' that takes one string: the full URL.
    sendMessage("JSBridge", "ReceiveURL", fullURL);
  };

  useEffect(() => {
    if (isLoaded) {
      alertUnityWithURL();
    }
  }, [isLoaded, alertUnityWithURL]); // Ensure this runs when Unity finishes loading


  const handlePassMetamaskInfo = (ethAddress: string, starkKey: string) => {
    sendMessage("JSBridge", "ProcessUserAddress", ethAddress);
  };

  useEffect(() => {
    let timeoutSendMessage: ReturnType<typeof setTimeout>;
    if (
      isConnected &&
      walletAddress.length > 2 &&
      starkKey.length > 2 &&
      isLoaded
    ) {
      // On here. Check message from unity game after loading game
      timeoutSendMessage = setTimeout(() => {
        handlePassMetamaskInfo(walletAddress, starkKey);
      }, 3000);
    }
    return () => {
      if (timeoutSendMessage) clearTimeout(timeoutSendMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, walletAddress, starkKey, isLoaded]);

  const handleWalletConnect = React.useCallback(() => {
    const connectWallet = async () => {
      const wallet = await onConnectWallet();
      handlePassMetamaskInfo(wallet.ethAddress, wallet.starkKey);
      console.log("Wallet -> ", wallet);
      if (wallet) {
        toast.success("Wallet is connected successfully.")
      } else {
        toast.warning("Wallet has failed connection. Please try again.")
      }
    };
    connectWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePassMetamaskInfo]);

  const handleOpenWager = React.useCallback(() => {
    console.log("on Open Wager", walletAddress);
    const getWalletInfo = async () => {
      if (!isConnected) {
        toast.warning("Wallet connection is not ready, click on Wallet Connect before opening Wager!");
        return;
      }

      if (!clientId) {
        toast.warning("Require client ID from game");
        return;
      }
        
      const wagerParams: Types.IWager = {
        clientId, // Game client generated
        env: EnvTypes.STAGING,
        walletAddress: walletAddress,
        starkKey,
        projectId: '2084'
      };
      const wagerManager = new Modules.WagerManager(wagerParams, 'http://localhost:3000/8-ball');
      wagerManager.openWager();
    };
    getWalletInfo();
  }, [walletAddress, isConnected, starkKey, clientId]);

  const handleClientId = useCallback((id:string) => {
    console.log('SetClientId -> ', id);
   
    setClientId(id);
  }, []);

  useEffect(() => {
    const eventWrapper = (event: any) => {
      console.log('Event => ', event);
      const id = String(event); // Convert number to string if necessary
      
      handleClientId(id);
    };
  
    addEventListener("SetClientId", eventWrapper);
    return () => {
      console.log("SetClientId Handler Removed");
      removeEventListener("SetClientId", eventWrapper);
    };
  }, [addEventListener, removeEventListener, handleClientId]);
  


  useEffect(() => {
    addEventListener("RequestOpenWager", (e: any) => {
      handleOpenWager();
    });
    return () => {
      removeEventListener("RequestOpenWager", (e: any) => handleOpenWager());
    };
  }, [handleOpenWager, addEventListener, removeEventListener]);

  useEffect(() => {
    addEventListener("RequestConnectWallet", (e: any) => {
      console.log("RequestConnectWallet");
      handleWalletConnect();
    });
    return () => {
      removeEventListener("RequestConnectWallet", (e: any) =>
        handleWalletConnect()
      );
    };
  }, [handleWalletConnect, addEventListener, removeEventListener]);

  return (
    <div className={styles.container}>
      <ToastContainer hideProgressBar />
      <div className={styles.buttonWrapper}>
      <div className={styles.fullScreen} onClick={() => requestFullscreen(true)}>FullScreen</div>
      <div className={styles.fullScreen} onClick={() => {
        const screenImgBase64 = takeScreenshot();

        //can be convert to img
        if(screenImgBase64) {
          navigator.clipboard.writeText(screenImgBase64).then(
            function () {
              toast('Capture screenshort success!', { type: 'success' });
            },
            function (err) {
              toast(err, { type: 'error' });
            }
          );
        }
        }}>TakeScreenShot</div>
      </div>
      <div className={styles.unityWrapper}>
        {isLoaded === false && (
          <>
            <div className={styles.loadingText}>
              <p>
                8 BALL FROM MYRIA ({(loadingProgression * 110).toFixed(2)}%)
              </p>
            </div>
            <div className={styles.loadingBar}>
              <div
                className={styles.loadingBarFill}
                style={{ width: loadingProgression * 800 }}
              />
            </div>
          </>
        )}
        <Unity
          unityProvider={unityProvider}
          style={{ display: isLoaded ? "block" : "none" }}
        />
      </div>
    </div>
  );
}

export default App;
